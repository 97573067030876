
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDH4SZ7qDNOCpJXEKnxxyWD3Qr4sDD8JXk",
  authDomain: "react-portfolio-dashboar-f0fa9.firebaseapp.com",
  projectId: "react-portfolio-dashboar-f0fa9",
  storageBucket: "react-portfolio-dashboar-f0fa9.appspot.com",
  messagingSenderId: "1069390082805",
  appId: "1:1069390082805:web:018896b844a7f5258f0bbc"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);


  